import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ng2-ckeditor';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import 'hammerjs';

import { TenantsService } from '../../services/tenants.service';
import { BackupsService } from '../../services/backups.service';
import { NewsService } from '../../services/news.service';
import { HelpService } from '../../services/help.service';
import { AppInsightsService } from '../../services/app.insights.service';
import { ReleaseNotesService } from '../../services/releasenotes.service';
import { UsersService } from '../../services/users.service';
import { ContactsService } from '../../services/contacts.service';

import { AppComponent } from './app.component';
import { NavMenuComponent } from '../navmenu/navmenu.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SettingsComponent } from '../settings/settings.component';
import { ReleaseNotesComponent } from '../releasenotes/releasenotes.component';
import { ReleaseNotesDetailComponent } from '../releasenotes/detail/releasenotes.detail.component';
import { ReleaseNotesNewDialogComponent } from './../releasenotes/new/releasenotes.new.component';
import { TenantsComponent } from '../tenants/tenants.component';
import { TenantDetailComponent, ConfirmDialogComponent } from '../tenants/detail/tenant.detail.component';
import { TenantDetailBackupsComponent } from '../tenants/detail/backups/backups.component';
import { TenantDetailUsersComponent } from '../tenants/detail/users/users.component';
import { TenantDetailContactsComponent } from '../tenants/detail/contacts/contacts.component';
import { TenantsNewDialogComponent } from './../tenants/new/tenants.new.component';
import { ContactsNewDialogComponent} from './../tenants/detail/contacts/new//contacts.new.component';
import { NewsComponent } from '../news/news.component';
import { NewsDetailComponent } from '../news/detail/news.detail.component';
import { NewsNewDialogComponent } from './../news/new/news.new.component';
import { HelpComponent } from '../help/help.component';
import { HelpDetailComponent } from '../help/detail/help.detail.component';
import { HelpNewDialogComponent } from '../help/new/help.new.component';
import { LicensesChartComponent } from '../tenants/license.chart/licenses.chart.component';
import { UsersCountComponent } from '../app.insights/users.count/users.count.component';
import { ServerResponseComponent } from './../dashboard/server-response/server-response.component';
import { TooltipAuditComponent } from '../tooltip/audit/tooltip-audit.component';
import { MarkdownEditorComponent } from '../../components/markdown-editor/markdown-editor.component';
import { TenantDataComponent } from './../dashboard/tenant-data/tenant-data.component';
import { UserDataComponent } from './../dashboard/user-data/user-data.component';
import { TenantUsageComponent } from '../tenants/detail/usage/tenant-usage.component';

import { ChartsModule as Ng2ChartsModule } from 'ng2-charts/ng2-charts';

// Google Material Imports
import {
  MatInputModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatTableModule,
  MatToolbarModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatTabsModule,
  MatSelectModule,
  MatIconModule,
  MatStepperModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatSortModule,
  MatTabChangeEvent,
  MatCheckbox
} from '@angular/material';
import { LookupPipe } from '../../pipes/lookup.pipe';
import { AdalService } from '../../services/adal/adal.service';
import { AdalInterceptor } from '../../services/adal/adal.interceptor';
import { MarkdownEditorImageDropdownComponent } from '../markdown-editor/image-dropdown/image-dropdown.component';
import { TenantUserEditDialogComponent } from '../tenants/detail/users/edit/edit.component';
import { TenantContactEditDialogComponent } from '../tenants/detail/contacts/edit/edit.contacts.component';
import { HTMLEditorComponent } from '../html-editor/html-editor.component';
import { ImageTreeviewComponent } from '../image-treeview/image-treeview.component';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    TenantsComponent,
    TenantDetailComponent,
    TenantsNewDialogComponent,
    TenantDetailBackupsComponent,
    TenantDetailUsersComponent,
    TenantDetailContactsComponent,
    NewsComponent,
    NewsDetailComponent,
    NewsNewDialogComponent,
    HelpComponent,
    HelpDetailComponent,
    HelpNewDialogComponent,
    DashboardComponent,
    SettingsComponent,
    UsersCountComponent,
    ServerResponseComponent,
    LicensesChartComponent,
    TooltipAuditComponent,
    ReleaseNotesComponent,
    ReleaseNotesDetailComponent,
    ReleaseNotesNewDialogComponent,
    MarkdownEditorComponent,
    MarkdownEditorImageDropdownComponent,
    TenantDataComponent,
    UserDataComponent,
    TenantUserEditDialogComponent,
    TenantContactEditDialogComponent,
    ContactsNewDialogComponent,
    HTMLEditorComponent,
    ImageTreeviewComponent,
    TenantUsageComponent,
    ConfirmDialogComponent,
    // Pipes
    LookupPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2ChartsModule,
    CKEditorModule,

    // Google Material
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSortModule,
    MatSlideToggleModule,

    RouterModule.forRoot([
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'tenants', component: TenantsComponent },
      { path: 'tenants/:id', component: TenantDetailComponent },
      { path: 'backup/:id', component: TenantDetailComponent },
      { path: 'releasenotes', component: ReleaseNotesComponent },
      { path: 'releasenotes/:id', component: ReleaseNotesDetailComponent },
      { path: 'news', component: NewsComponent },
      { path: 'news/:id', component: NewsDetailComponent },
      { path: 'help', component: HelpComponent },
      { path: 'help/:id', component: HelpDetailComponent },
      { path: '**', redirectTo: 'dashboard' }
    ])
  ],
  entryComponents: [
    NewsNewDialogComponent,
    HelpNewDialogComponent,
    ReleaseNotesNewDialogComponent,
    TenantsNewDialogComponent,
    MarkdownEditorImageDropdownComponent,
    TenantUserEditDialogComponent,
    TenantContactEditDialogComponent,
    ContactsNewDialogComponent,
    ConfirmDialogComponent
  ],
  providers: [
    AdalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdalInterceptor,
      multi: true
    },
    BackupsService,
    ReleaseNotesService,
    TenantsService,
    NewsService,
    HelpService,
    AppInsightsService,
    UsersService,
    ContactsService,
    { provide: 'BASE_URL', useFactory: getBaseUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
