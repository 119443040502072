import { ITenant } from './../../../interfaces/iTenant';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TenantsService } from '../../../services/tenants.service';
import { BackupsService } from '../../../services/backups.service';
import { ContactsService } from '../../../services/contacts.service';
import { IBackup } from '../../../interfaces/iBackup';
import { DataSource } from '@angular/cdk/collections';
import { switchMap } from 'rxjs/operators';

import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactsNewDialogComponent } from './contacts/new/contacts.new.component';
import { AdalService } from '../../../services/adal/adal.service';
import { TenantDetailContactsComponent } from './contacts/contacts.component';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title>Confirm Action</h1>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
    </div>
  `,
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {}
}

@Component({
  selector: 'app-tenant-detail',
  templateUrl: './tenant.detail.component.html',
  styleUrls: ['./tenant.detail.component.scss'],
  entryComponents: [ConfirmDialogComponent]
})
export class TenantDetailComponent implements OnInit {
  [x: string]: any;
  public tenant: ITenant;
  public audits: ITenant[];
  public backups: any[];
  public isContactActive: boolean;
  public licenseTypes: any[];
  public enable2FAchanged: boolean = false;
  public isAIDataExtractionEnabled: boolean = false;
  public isAIDeDebuggerEnabled: boolean = false;

  @ViewChild('tenantForm') tenantForm: NgForm;
  @ViewChild(TenantDetailContactsComponent) tenantDetailContactsComponent: TenantDetailContactsComponent;

  public auditColumns = [
    'updatedDate',
    'updatedBy',
    'name',
    'status',
    'adminFirstName',
    'adminLastName',
    'adminEmail',
    'adminPhoneDirect',
    'adminPhoneCell',
    'customerId',
    'physicalAddress1',
    'physicalAddress2',
    'physicalAddressCity',
    'physicalAddressState',
    'physicalAddressZip',
    'licenseType',
    'licensesSubmitterFull',
    'licensesFull',
    'readonlyLicenses',
    'approverLicenses'
  ];

  private _auditData: BehaviorSubject<ITenant[]> = new BehaviorSubject<
    ITenant[]
  >([]);
  public auditDataSource = new AuditDataSource(this._auditData.asObservable());

  constructor(
    private route: ActivatedRoute,
    private _tenantsService: TenantsService,
    private _contactsService: ContactsService,
    private _backupsService: BackupsService,
    private _adalService: AdalService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .switchMap((params: ParamMap) =>
        this._tenantsService.getById(parseInt(params.get('id'))).then(result => result)
      )
      .subscribe((tenant: any) => {
        tenant.createdDate = new Date(Date.parse(tenant.createdDate));
        this.tenant = tenant;
        console.log('tenant.isApiIntegrated', tenant.isApiIntegrated);
        console.log('tenant',tenant);
      });

    this._tenantsService.getLicenseTypes().then(
      (res) => {
        this.licenseTypes = res;
        console.log(this.licenseTypes);
      },
      err => {
        console.log(err);
      }
    );
  }

  setAIDeEnabled(isEnabled: boolean): void {
    if (this.tenant && this.tenant.id) {
      this.tenant.isAIDataExtractionEnabled = isEnabled;
      this._tenantsService.setAIDeEnabled(this.tenant.id, isEnabled)
        .then((updatedTenant: ITenant) => {
          this.tenant = updatedTenant;
          const msg = isEnabled ? 'AI DE Enabled' : 'AI DE Disabled';
          this._snackBar.open(msg, 'Close', {
            duration: 2000,
          });
          // If AI DE is disabled, also disable AI DE Debugger
          if (!isEnabled && (this.tenant as any).isAIDeDebuggerEnabled) {
            this.setAIDeDebuggerEnabled(false);
          }
        })
        .catch((error: string) => {
          console.error('Error setting AI DE Enabled:', error);
          this._snackBar.open('Failed to update AI DE status', 'Close', {
            duration: 2000,
          });
          // Revert the toggle if there was an error
          (this.tenant as any).isAIDeEnabled = !isEnabled;
        });
    }
  }

  setAIDeDebuggerEnabled(isEnabled: boolean): void {
    if (this.tenant && this.tenant.id) {
      this._tenantsService.setAIDeDebuggerEnabled(this.tenant.id, isEnabled)
        .then((updatedTenant: ITenant) => {
          this.tenant = updatedTenant;
          const msg = isEnabled ? 'AI DE Debugger Enabled' : 'AI DE Debugger Disabled';
          this._snackBar.open(msg, 'Close', {
            duration: 2000,
          });
        })
        .catch((error: string) => {
          console.error('Error setting AI DE Debugger Enabled:', error);
          this._snackBar.open('Failed to update AI DE Debugger status', 'Close', {
            duration: 2000,
          });
          // Revert the toggle if there was an error
          (this.tenant as any).isAIDeDebuggerEnabled = !isEnabled;
        });
    }
  }


  saveCustomer(): void {
    const date = new Date();
    const putTenant: any = this.tenant;
    putTenant.updatedDate = date.toISOString();

    this._tenantsService.put(putTenant).then(
      () => {
        this.tenant = putTenant;
        this._snackBar.open('Saved!', 'Close message', {
          duration: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  enableApi(checkboxValue: any): void{
    console.log("enableApi: " + checkboxValue);
    this._tenantsService.enableApi(this.tenant.id, checkboxValue).then(
      () => {
        var msg = checkboxValue ? 'Api Enabled' : 'Api Disabled';
        this._snackBar.open(msg, 'Close', {
          duration: 2000,
        });
      },
      (err) => {
        this._snackBar.open('API Failed.', 'Close', {
          duration: 2000,
        });
        console.log(err);
      }
    );
  }

  enableWorkato(checkboxValue: boolean): void {
    console.log("enableWorkato: " + checkboxValue);

    if (!checkboxValue) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: {
          message: 'Disabling Workato will delete the Workato profile and all of its contents (recipes and connections). Are you sure you want to proceed?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.proceedWithWorkatoChange(checkboxValue);
        } else {
          // If the user cancels, we don't need to do anything
          // as the checkbox state hasn't been changed yet
        }
      });
    } else {
      this.proceedWithWorkatoChange(checkboxValue);
    }
  }

  private proceedWithWorkatoChange(checkboxValue: boolean): void {
    this._tenantsService.enableWorkato(this.tenant.id, checkboxValue).then(
      () => {
        const msg = checkboxValue ? 'Workato Enabled' : 'Workato Disabled';
        this._snackBar.open(msg, 'Close', {
          duration: 2000,
        });
      },
      (err) => {
        this._snackBar.open('Workato Failed.', 'Close', {
          duration: 2000,
        });
        console.log(err);
      }
    );
  }

  enableCollab(checkboxValue: any): void{
    console.log("enableCollab: " + checkboxValue);
    this._tenantsService.enableCollab(this.tenant.id, checkboxValue).then(
      () => {
        var msg = checkboxValue ? 'Collab Enabled' : 'Collab Disabled';
        this._snackBar.open(msg, 'Close', {
          duration: 2000,
        });
      },
      (err) => {
        this._snackBar.open('Collab Failed.', 'Close', {
          duration: 2000,
        });
        console.log(err);
      }
    );
  }

  enableTwoFactor(checkboxValue: any): void {
    console.log('enableTwoFactor: ' + checkboxValue);
    this.enable2FAchanged = true;
    this.tenant.is2FAEnabledForAllUsers = checkboxValue;
  }

  onSetTenantStatus(status: boolean): void {
    this.tenant.suspended = status;
    this.saveCustomer();
  }

  backupCustomer(): void {
    this._backupsService.put(this.tenant.id).then(
      () => {
        this._snackBar.open('Backup Started!', 'Close message', {
          duration: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  public addContact() {
    const dialogRef = this.dialog.open(ContactsNewDialogComponent, {
      width: '400px',
      data: this._contactsService.getEmpty()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const results = result;
        result.updatedBy = this._adalService.userInfo.username;
        result.createdBy = this._adalService.userInfo.username;
        result.tenantId = this.tenant.id;
        this._contactsService.post(result).then(newContact => {
          this._snackBar.open('Created!', 'Close message', {
            duration: 2000
          });
          this.tenantDetailContactsComponent.refeshData();
        });
      }
    });
  }

  onToggleAudits(value: boolean): void {
    this.showAudits = value;
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 2) {
      this.isContactActive = true;
    }
    else {
      this.isContactActive = false;
    }
  }

  public sendExportLinkToTenantOwner(): void {
    this._tenantsService.exportAllTenantData(this.tenant.id).then(
      () => {
        this._snackBar.open('The link has been emailed to the tenant owner(s)', 'Close message', {
          duration: 2000,
        });
      },
      err => {
        this._snackBar.open(err.error.message, 'Close message', {
          duration: 2000,
        });
      }
    );
  }

  public deleteTenant(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: { message: 'Are you sure you want to delete this tenant?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tenant.status = 'Deleted';
        this._tenantsService.deleteTenant(this.tenant.id).then(
          () => {
            this._snackBar.open('Tenant marked as deleted successfully', 'Close', {
              duration: 2000,
            });
            this._router.navigate(['/tenants']);
          },
          (err) => {
            this._snackBar.open('Failed to delete tenant', 'Close', {
              duration: 2000,
            });
            console.log(err);
          }
        );
      }
    });




  }
}

export class AuditDataSource extends DataSource<any> {
  private _data: Observable<ITenant[]>;

  constructor(data: Observable<ITenant[]>) {
    super();
    this._data = data;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ITenant[]> {
    return this._data;
  }

  disconnect() {}
}

